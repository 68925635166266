<script setup lang="ts">
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { ref, computed, onMounted } from 'vue'
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useEntityStore } from '@/modules/investing/stores/entity-store'
import { useInvestingInvestorStore } from '@/modules/investing/stores/investor-store'
import {
  PortfolioSection,
  VButton,
  VDropdownImproved,
  VIcon,
  VSection,
  TheContent,
  VDescriptionList,
} from '@/modules/shared/components'
import DashboardLayout from '@/modules/shared/layouts/dashboard-layout.vue'
import { createOptions } from '@/modules/shared/utils/form'
import { rails_url } from '@/modules/shared/utils/rails'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import DealCard from '../components/deal-card.vue'
import { useDealStore } from '../stores/deal-store'
import { usePortfolioStore } from '@/modules/investing/stores/portfolio-store'
import AllEntitiesTable from '@/modules/investing/components/all-entities-table.vue'
import { useOtherEntityStore } from '@/modules/investing/stores/other-entity-store'

const workspaceStore = useWorkspaceStore()

const { t } = useExtendedI18n()

// options

const asOfDate = ref(format(new Date(), 'yyyy-MM-dd'))

const skeleton = ref(true)
const dealStore = useDealStore()
const otherEntityStore = useOtherEntityStore()
const investorStore = useInvestingInvestorStore()
const entityStore = useEntityStore()
const authStore = useAuthStore()
const current_user = computed(() => authStore.current_user)
const isAdmin = computed(() => current_user.value.role !== 'investor')
const selectedProfileKeys = ref([])
const portfolioStore = usePortfolioStore()
const cid = computed(() => {
  if (authStore.is_site_admin) return `site-admin:${current_user.value.id}`
  if (authStore.is_site_or_group_admin) return `group-admin:${current_user.value.id}`
  if (current_user.value.investor_id) return `individual:${current_user.value.investor_id}`
})

const deals = computed(() => (skeleton.value ? [{}, {}, {}, {}, {}] : dealStore.deals))

const investments = computed(() => {
  if (isAdmin.value || selectedProfileKeys.value.length === profileOptions.value.length)
    return portfolioStore.investments
  return portfolioStore.investments.filter((investment) => selectedProfileKeys.value.includes(investment.investor_cid))
})

///////////////////////////////////////////////////////////////////////////////
// Profiles
///////////////////////////////////////////////////////////////////////////////
const profileOptions = computed(() => createOptions(investorStore.profiles, { label: 'name' }))
const viewingAs = computed(() => {
  if (selectedProfileKeys.value.length === Object.keys(investorStore.profiles).length) {
    return `all ${t('shared.investor', 0)}`
  }

  if (selectedProfileKeys.value.length === 1) {
    return investorStore.profiles[selectedProfileKeys.value[0]].name
  }

  if (selectedProfileKeys.value.length > 1 || selectedProfileKeys.value.length < 1) {
    return `${selectedProfileKeys.value.length} ${t('shared.investor', selectedProfileKeys.value.length)}`
  }
})

const getPortfolioCID = () => {
  let _cid
  switch (current_user.value.role) {
    case 'site_admin':
    case 'group_admin':
      _cid = `${current_user.value.role}:${current_user.value.id}`
      break
    case 'investor':
      _cid = `individual:${current_user.value.investor_id}`
      break
    default:
      break
  }

  return _cid
}

///////////////////////////////////////////////////////////////////////////////
// Deals
///////////////////////////////////////////////////////////////////////////////

const can_view_featured_deals = computed(() => is_current_investor.value)

const has_featured_deals = computed(() => deals.value.length > 0)

const is_current_investor = computed(() => ['active', 'past-due'].includes(authStore.current_user.status))

onMounted(async () => {
  await workspaceStore.fetchCurrent()

  if (isAdmin.value) {
    await Promise.all([
      dealStore.fetchDeals({ is_featured: true, limit: 3, order: 'asc' }),
      portfolioStore.fetchInvestments(`group:${workspaceStore.current.id}`, { as_of: asOfDate.value }),
      investorStore.fetchProfiles(),
      entityStore.fetchEntities('spv'),
      entityStore.fetchEntities('fund'),
    ])
  } else {
    await Promise.all([
      dealStore.fetchDeals({ is_featured: true, limit: 3, order: 'asc' }),
      portfolioStore.fetchPortfolio({
        _cid: getPortfolioCID(),
        as_of_date: new Date(),
      }),
    ])
  }

  await Promise.all([
    investorStore.fetchProfiles(),
    entityStore.fetchEntities('spv'),
    entityStore.fetchEntities('fund'),
  ])

  if (!isAdmin.value) {
    otherEntityStore.fetchOtherEntities()
  }

  selectedProfileKeys.value = Object.keys(investorStore.profiles)
  skeleton.value = false
})
</script>

<template>
  <DashboardLayout>
    <VSection>
      <h2 class="mb-4 text-2xl font-bold leading-5 text-gray-900 dark:text-gray-300 sm:text-3xl sm:leading-7">
        Hello, {{ current_user.name }}
      </h2>
    </VSection>

    <VSection v-if="!isAdmin">
      <div class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] px-2 py-3 sm:px-4">
        <div class="flex flex-col-reverse justify-start gap-2 sm:flex-row sm:items-center">
          <VDropdownImproved v-model="selectedProfileKeys" aligned="left" :options="profileOptions" class="z-[1]">
            <VButton size="md">
              <div class="flex items-center space-x-2">
                <div>Filter by {{ viewingAs }}</div>
                <VIcon name="chevron_selector_vertical" />
              </div>
            </VButton>
          </VDropdownImproved>

          <p class="text-sm font-medium text-gray-700">
            Use this filter to view your portfolio as a specific investor or vehicle
          </p>
        </div>
      </div>
    </VSection>

    <TheContent :disableSideSection="isAdmin">
      <template #main>
        <VSection label="Featured Deals" v-if="has_featured_deals && can_view_featured_deals">
          <template #actions>
            <a :href="`${rails_url()}/list/featured`" class="text-sm text-[#3D8A9D] underline">View All</a>
          </template>
          <div class="flex max-w-full gap-3 overflow-x-auto">
            <DealCard v-for="deal in deals" :deal="deal" :skeleton="skeleton" />
          </div>
        </VSection>

        <PortfolioSection :investments="investments" :skeleton="skeleton" :stack="!isAdmin" />

        <AllEntitiesTable
          :cid="cid"
          :filterKeys="isAdmin ? null : selectedProfileKeys"
          :skeleton="skeleton"
          :funds="entityStore.funds"
          :spvs="entityStore.spvs"
          :other_entities="otherEntityStore.other_entities"
        />
      </template>
      <template #aside v-if="!isAdmin">
        <div class="mb-1 flex items-center justify-between px-0 pb-1">
          <div>
            <h3 class="font-medium leading-7 text-gray-700 dark:text-gray-300">
              {{ capitalize(t('shared.beneficiary', 0)) }}
            </h3>
          </div>
          <div>
            <RouterLink
              class="hyperlink"
              :to="{ name: 'investing.individual.beneficiaries', params: { individual_id: current_user.investor_id } }"
            >
              View
            </RouterLink>
          </div>
        </div>
        <div class="mb-1 flex items-center justify-between px-0 pb-1">
          <div>
            <h3 class="font-medium leading-7 text-gray-700 dark:text-gray-300">
              {{ capitalize(t('shared.contact', 0)) }}
            </h3>
          </div>
          <div>
            <RouterLink
              class="hyperlink"
              :to="{ name: 'investing.individual.contacts', params: { individual_id: current_user.investor_id } }"
            >
              View
            </RouterLink>
          </div>
        </div>
      </template>
    </TheContent>
  </DashboardLayout>
</template>
